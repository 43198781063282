/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import {Stack, Banner, BodyText, Headline, Button, Card, Form} from '@servicetitan/design-system';
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    pre: "pre",
    code: "code",
    hr: "hr",
    h1: "h1",
    a: "a",
    div: "div",
    h2: "h2",
    ul: "ul",
    li: "li",
    strong: "strong"
  }, _provideComponents(), props.components);
  if (!Form) _missingMdxReference("Form", false);
  if (!Form.Group) _missingMdxReference("Form.Group", true);
  if (!Form.Radio) _missingMdxReference("Form.Radio", true);
  if (!Stack) _missingMdxReference("Stack", false);
  if (!Stack.Item) _missingMdxReference("Stack.Item", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Radio Inputs are quicker for a user to scan and should be used for choosing between small sets of items, usually 5 or less, and the user must select exactly one option. Since the options are always visible, they are easier to compare against and can provide additional context through helper text or a help tooltip so a user can choose accurately."), "\n", React.createElement(Banner, {
    status: "success",
    title: "Do this"
  }), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<State initial={1}>\n\t{([state, setState]) => (\n\t\t<Form>\n\t\t\t<Form.Group grouped>\n\t\t\t\t<label>Organization</label>\n\t\t\t\t<Form.Radio\n\t\t\t\t\tlabel={<span><strong>Rebel Alliance</strong><BodyText el=\"div\" size=\"small\" className=\"m-b-half\">The Good Guys</BodyText></span>}\n\t\t\t\t\tvalue=\"1\"\n\t\t\t\t\tchecked={state === \"1\"}\n\t\t\t\t\tonChange={ (value) => setState(value) }\n\t\t\t\t\tname=\"Radio-sample\"\n\t\t\t\t/>\n\t\t\t\t<Form.Radio\n\t\t\t\t\tlabel={<span><strong>Galactic Empire</strong><BodyText el=\"div\" size=\"small\" className=\"m-b-half\">The Bad Guys</BodyText></span>}\n\t\t\t\t\tvalue=\"2\"\n\t\t\t\t\tname=\"Radio-sample\"\n\t\t\t\t\tchecked={state === \"2\"}\n\t\t\t\t\tonChange={ (value) => setState(value) }\n\t\t\t\t/>\n\t\t\t\t<Form.Radio\n\t\t\t\t\tlabel={<span><strong>Trade Federation</strong><BodyText el=\"div\" size=\"small\" className=\"m-b-half\">Neutral</BodyText></span>}\n\t\t\t\t\tvalue=\"3\"\n\t\t\t\t\tname=\"Radio-sample\"\n\t\t\t\t\tchecked={state === \"3\"}\n\t\t\t\t\tonChange={ (value) => setState(value) }\n\t\t\t\t/>\n\t\t\t</Form.Group>\n\t\t</Form>\n\t)}\n</State>\n")), "\n", React.createElement(Banner, {
    status: "critical",
    title: "Don't do this"
  }), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<Form className=\"m-b-8\">\n\t<Form.Select open label=\"Organization\" placeholder=\"Choose Organization\" options={[\n\t\t{key:1, value:1, text: 'Rebel Alliance'},\n\t\t{key:2, value:2, text: 'Galactic Empire'},\n\t\t{key:3, value:3, text: 'Trade Federation'},\n\t]} />\n</Form>\n")), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h1, {
    id: "sizing",
    style: {
      position: "relative"
    }
  }, "Sizing", React.createElement(_components.a, {
    href: "#sizing",
    "aria-label": "sizing permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "Radios can be sized to be small, medium, and large."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<Form>\n\t<Form.Group grouped>\n\t\t<label>Small Radio</label>\n\t\t<Form.Radio\n\t\t\tlabel=\"Force User\"\n\t\t\tsize=\"small\"\n\t\t\tname=\"Radio-sample\"\n\t\t/>\n\t\t<Form.Radio\n\t\t\tlabel=\"Pilot\"\n\t\t\tsize=\"small\"\n\t\t\tname=\"Radio-sample\"\n\t\t/>\n\t\t<Form.Radio\n\t\t\tlabel=\"Bounty Hunter\"\n\t\t\tsize=\"small\"\n\t\t\tname=\"Radio-sample\"\n\t\t/>\n\t</Form.Group>\n</Form>\n")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<Form>\n\t<Form.Group grouped>\n\t\t<label>Medium Radio</label>\n\t\t<Form.Radio\n\t\t\tlabel=\"Force User\"\n\t\t\tname=\"Radio-sample\"\n\t\t/>\n\t\t<Form.Radio\n\t\t\tlabel=\"Pilot\"\n\t\t\tname=\"Radio-sample\"\n\t\t/>\n\t\t<Form.Radio\n\t\t\tlabel=\"Bounty Hunter\"\n\t\t\tname=\"Radio-sample\"\n\t\t/>\n\t</Form.Group>\n</Form>\n")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<Form>\n\t<Form.Group grouped>\n\t\t<label>Large Radio</label>\n\t\t<Form.Radio\n\t\t\tlabel=\"Force User\"\n\t\t\tsize=\"large\"\n\t\t\tname=\"Radio-sample\"\n\t\t/>\n\t\t<Form.Radio\n\t\t\tlabel=\"Pilot\"\n\t\t\tsize=\"large\"\n\t\t\tname=\"Radio-sample\"\n\t\t/>\n\t\t<Form.Radio\n\t\t\tlabel=\"Bounty Hunter\"\n\t\t\tsize=\"large\"\n\t\t\tname=\"Radio-sample\"\n\t\t/>\n\t</Form.Group>\n</Form>\n")), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h1, {
    id: "states",
    style: {
      position: "relative"
    }
  }, "States", React.createElement(_components.a, {
    href: "#states",
    "aria-label": "states permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<Form>\n\t<Form.Group grouped>\n\t\t<label>Error States</label>\n\t\t<Form.Radio\n\t\t\terror\n\t\t\tlabel=\"Force User\"\n\t\t\tname=\"Radio-sample\"\n\t\t/>\n\t\t<Form.Radio\n\t\t\terror\n\t\t\tlabel=\"Pilot\"\n\t\t\tname=\"Radio-sample\"\n\t\t/>\n\t\t<Form.Radio\n\t\t\tlabel=\"Bounty Hunter\"\n\t\t\terror\n\t\t\tname=\"Radio-sample\"\n\t\t/>\n\t</Form.Group>\n</Form>\n")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<Form>\n\t<Form.Group grouped>\n\t\t<label>Disabled States</label>\n\t\t<Form.Radio\n\t\t\tdisabled\n\t\t\tlabel=\"Force User\"\n\t\t\tname=\"Radio-sample\"\n\t\t/>\n\t\t<Form.Radio\n\t\t\tdisabled\n\t\t\tlabel=\"Pilot\"\n\t\t\tname=\"Radio-sample\"\n\t\t/>\n\t\t<Form.Radio\n\t\t\tdisabled\n\t\t\tlabel=\"Bounty Hunter\"\n\t\t\tname=\"Radio-sample\"\n\t\t/>\n\t</Form.Group>\n</Form>\n")), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h1, {
    id: "labels-and-helper-text",
    style: {
      position: "relative"
    }
  }, "Labels and Helper Text", React.createElement(_components.a, {
    href: "#labels-and-helper-text",
    "aria-label": "labels and helper text permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.h2, {
    id: "no-label-radio",
    style: {
      position: "relative"
    }
  }, "No Label Radio", React.createElement(_components.a, {
    href: "#no-label-radio",
    "aria-label": "no label radio permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "A radio can be isolated, without margins or label text. This can be useful for more complex labels."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<Radio />\n")), "\n", React.createElement(_components.h2, {
    id: "label-help",
    style: {
      position: "relative"
    }
  }, "Label Help", React.createElement(_components.a, {
    href: "#label-help",
    "aria-label": "label help permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "Labels can have a help icon with a ", React.createElement(_components.a, {
    href: "/components/tooltip"
  }, "tooltip"), " to provide additional context to a label."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<Form>\n\t<Form.Radio\n\t\tlabel=\"Input Label\"\n\t\tlabelProps={{\n\t\t\thelp: \"This is help text\"\n\t\t}}\n\t/>\n</Form>\n")), "\n", React.createElement(_components.h2, {
    id: "helper-text",
    style: {
      position: "relative"
    }
  }, "Helper Text", React.createElement(_components.a, {
    href: "#helper-text",
    "aria-label": "helper text permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<Form>\n\t<Form.Radio\n\t\tlabel=\"Description Text\"\n\t\tdescription=\"Description goes here...\"\n\t\tname=\"Radio-sample\"\n\t/>\n\t<Form.Radio\n\t\tlabel=\"Error Text\"\n\t\terror=\"You must choose a selection.\"\n\t\tname=\"Radio-sample\"\n\t/>\n\t<Form.Radio\n\t\tlabel=\"Both\"\n\t\terror=\"You must choose a selection.\"\n\t\tdescription=\"Description goes here...\"\n\t\tname=\"Radio-sample\"\n\t/>\n</Form>\n")), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h1, {
    id: "custom-label-position",
    style: {
      position: "relative"
    }
  }, "Custom Label Position", React.createElement(_components.a, {
    href: "#custom-label-position",
    "aria-label": "custom label position permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "Radios by default have their label to the left. A custom text label can be applied using the standard ", React.createElement(_components.code, null, "label"), ", ", React.createElement(_components.code, null, "for"), ", and ", React.createElement(_components.code, null, "id"), " combination found with HTML radios."), "\n", React.createElement(_components.h1, {
    id: "examples-of-a-label-on-the-left",
    style: {
      position: "relative"
    }
  }, "Examples of a label on the left", React.createElement(_components.a, {
    href: "#examples-of-a-label-on-the-left",
    "aria-label": "examples of a label on the left permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<Form style={{width: '200px'}}>\n\t<Form.Group grouped>\n\t\t<Stack\n\t\t\talignItems=\"center\"\n\t\t\tjustifyContent=\"space-between\"\n\t\t>\n\t\t\t<BodyText\n\t\t\t\tel=\"label\"\n\t\t\t\thtmlFor=\"radio-id1\"\n\t\t\t\tclassName=\"ta-right m-r-1\"\n\t\t\t>\n\t\t\t\tShort Label\n\t\t\t</BodyText>\n\t\t\t<Form.Radio\n\t\t\t\tid=\"radio-id1\"\n\t\t\t\tname=\"Radio-sample\"\n\t\t\t/>\n\t\t</Stack>\n\t\t<Stack\n\t\t\talignItems=\"center\"\n\t\t\tjustifyContent=\"space-between\"\n\t\t>\n\t\t\t<BodyText\n\t\t\t\tel=\"label\"\n\t\t\t\thtmlFor=\"radio-id2\"\n\t\t\t\tclassName=\"ta-right m-r-1\"\n\t\t\t>\n\t\t\t\tCustom Label\n\t\t\t</BodyText>\n\t\t\t<Form.Radio\n\t\t\t\tid=\"radio-id2\"\n\t\t\t\tname=\"Radio-sample\"\n\t\t\t/>\n\t\t</Stack>\n\t\t<Stack\n\t\t\talignItems=\"center\"\n\t\t\tjustifyContent=\"space-between\"\n\t\t>\n\t\t\t<BodyText\n\t\t\t\tel=\"label\"\n\t\t\t\thtmlFor=\"radio-id3\"\n\t\t\t\tclassName=\"ta-right m-r-1\"\n\t\t\t>\n\t\t\t\tA Longer Radio Label\n\t\t\t</BodyText>\n\t\t\t<Form.Radio\n\t\t\t\tid=\"radio-id3\"\n\t\t\t\tname=\"Radio-sample\"\n\t\t\t/>\n\t\t</Stack>\n\t</Form.Group>\n</Form>\n")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<Form>\n\t<Form.Group grouped>\n\t\t<Stack\n\t\t\talignItems=\"center\"\n\t\t\tjustifyContent=\"flex-end\"\n\t\t>\n\t\t\t<BodyText\n\t\t\t\tel=\"label\"\n\t\t\t\thtmlFor=\"radio-id4\"\n\t\t\t\tclassName=\"ta-right m-r-1\"\n\t\t\t>\n\t\t\t\tShort Label\n\t\t\t</BodyText>\n\t\t\t<Form.Radio\n\t\t\t\tid=\"radio-id4\"\n\t\t\t\tname=\"Radio-sample\"\n\t\t\t/>\n\t\t</Stack>\n\t\t<Stack\n\t\t\talignItems=\"center\"\n\t\t\tjustifyContent=\"flex-end\"\n\t\t>\n\t\t\t<BodyText\n\t\t\t\tel=\"label\"\n\t\t\t\thtmlFor=\"radio-id5\"\n\t\t\t\tclassName=\"ta-right m-r-1\"\n\t\t\t>\n\t\t\t\tCustom Label\n\t\t\t</BodyText>\n\t\t\t<Form.Radio\n\t\t\t\tid=\"radio-id5\"\n\t\t\t\tname=\"Radio-sample\"\n\t\t\t/>\n\t\t</Stack>\n\t\t<Stack\n\t\t\talignItems=\"center\"\n\t\t\tjustifyContent=\"flex-end\"\n\t\t>\n\t\t\t<BodyText\n\t\t\t\tel=\"label\"\n\t\t\t\thtmlFor=\"radio-id6\"\n\t\t\t\tclassName=\"ta-right m-r-1\"\n\t\t\t>\n\t\t\t\tA Longer Radio Label\n\t\t\t</BodyText>\n\t\t\t<Form.Radio\n\t\t\t\tid=\"radio-id6\"\n\t\t\t\tname=\"Radio-sample\"\n\t\t\t/>\n\t\t</Stack>\n\t</Form.Group>\n</Form>\n")), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h1, {
    id: "best-practices",
    style: {
      position: "relative"
    }
  }, "Best practices", React.createElement(_components.a, {
    href: "#best-practices",
    "aria-label": "best practices permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "Radio buttons should:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Always be used with an associated label component."), "\n", React.createElement(_components.li, null, "Be part of a list of radio buttons that:", "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Include at least two or more choices."), "\n", React.createElement(_components.li, null, "Are used to have users select only one option."), "\n", React.createElement(_components.li, null, "Include mutually exclusive options — this means that each option must be independent from every other option in the list. For example: Red, blue, and yellow are mutually exclusive. Red, blue, yellow, red/blue are not mutually exclusive."), "\n", React.createElement(_components.li, null, "List options in a rational order that makes logical sense."), "\n"), "\n"), "\n"), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h1, {
    id: "content-guidelines",
    style: {
      position: "relative"
    }
  }, "Content Guidelines", React.createElement(_components.a, {
    href: "#content-guidelines",
    "aria-label": "content guidelines permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.h2, {
    id: "the-radio-groups-title-and-description-help-communicate-its-main-purpose",
    style: {
      position: "relative"
    }
  }, "The radio group’s title and description help communicate its main purpose", React.createElement(_components.a, {
    href: "#the-radio-groups-title-and-description-help-communicate-its-main-purpose",
    "aria-label": "the radio groups title and description help communicate its main purpose permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "Titles should clearly communicate at a glance what the purpose of the radio group is. Descriptions should provide more context and information about the decision the user will have to make."), "\n", React.createElement(Stack, {
    spacing: 3
  }, React.createElement(Stack.Item, {
    grow: 1,
    basis: 0
  }, React.createElement(Card, null, React.createElement(Stack, {
    direction: "column",
    spacing: 2
  }, React.createElement(Form, null, React.createElement(Form.Group, {
    grouped: true
  }, React.createElement(Headline, {
    size: "small"
  }, "Assign geofence tracking to vehicles"), React.createElement(BodyText, null, "A geofence can be applied to a selection of individual vehicles or assigned to one or more vehicle groups."), React.createElement(Form.Radio, {
    label: "Apply to individual vehicles",
    name: "Radio-sample"
  }), React.createElement(Form.Radio, {
    label: "Apply to vehicle groups",
    name: "Radio-sample"
  }))))), React.createElement(Banner, {
    status: "success",
    title: "Do"
  })), React.createElement(Stack.Item, {
    grow: 1,
    basis: 0
  }, React.createElement(Card, null, React.createElement(Stack, {
    direction: "column",
    spacing: 2
  }, React.createElement(Form, null, React.createElement(Form.Group, {
    grouped: true
  }, React.createElement(Headline, {
    size: "small"
  }, "Select vehicles to monitor"), React.createElement(Form.Radio, {
    label: "Apply to individual vehicles",
    name: "Radio-sample"
  }), React.createElement(Form.Radio, {
    label: "Apply to vehicle groups",
    name: "Radio-sample"
  }))))), React.createElement(Banner, {
    status: "critical",
    title: "Don't"
  }))), "\n", React.createElement(_components.h2, {
    id: "use-descriptive-radio-input-labels",
    style: {
      position: "relative"
    }
  }, "Use descriptive radio input labels", React.createElement(_components.a, {
    href: "#use-descriptive-radio-input-labels",
    "aria-label": "use descriptive radio input labels permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "The user relies on the label to provide context and identify what the radio input controls. Use positive and active wording for radio input labels."), "\n", React.createElement(_components.p, null, "Use sentence case (capitalize only the first word and proper nouns) so control labels are easy to scan. Do not use periods for short phrases or single sentences."), "\n", React.createElement(Stack, {
    spacing: 3
  }, React.createElement(Stack.Item, {
    grow: 1,
    basis: 0
  }, React.createElement(Card, null, React.createElement(Stack, {
    direction: "column",
    spacing: 2
  }, React.createElement(Form, null, React.createElement(Form.Group, {
    grouped: true
  }, React.createElement(Form.Radio, {
    label: "Leaky bathroom faucet",
    name: "Radio-sample"
  }), React.createElement(Form.Radio, {
    label: "Clogged kitchen drain",
    name: "Radio-sample"
  }), React.createElement(Form.Radio, {
    label: "No hot water",
    name: "Radio-sample"
  }), React.createElement(Form.Radio, {
    label: "Slow shower drain",
    name: "Radio-sample"
  }), React.createElement(Form.Radio, {
    label: "Overflowing toilet",
    name: "Radio-sample"
  }))))), React.createElement(Banner, {
    status: "success",
    title: "Do"
  })), React.createElement(Stack.Item, {
    grow: 1,
    basis: 0
  }, React.createElement(Card, null, React.createElement(Stack, {
    direction: "column",
    spacing: 2
  }, React.createElement(Form, null, React.createElement(Form.Group, {
    grouped: true
  }, React.createElement(Form.Radio, {
    label: "Fix water problem",
    name: "Radio-sample"
  }), React.createElement(Form.Radio, {
    label: "Plumbing emergency",
    name: "Radio-sample"
  }), React.createElement(Form.Radio, {
    label: "Water not working",
    name: "Radio-sample"
  }), React.createElement(Form.Radio, {
    label: "Broken pipe",
    name: "Radio-sample"
  }), React.createElement(Form.Radio, {
    label: "Plumbing issue",
    name: "Radio-sample"
  }))))), React.createElement(Banner, {
    status: "critical",
    title: "Don't"
  }))), "\n", React.createElement(_components.h2, {
    id: "choose-a-logical-sequence-for-input-choices",
    style: {
      position: "relative"
    }
  }, "Choose a logical sequence for input choices", React.createElement(_components.a, {
    href: "#choose-a-logical-sequence-for-input-choices",
    "aria-label": "choose a logical sequence for input choices permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "Group input choices logically and based on usage frequency (e.g*., Standard shipping, 2-day shipping, 1-day shipping*). The most common values first should appear at the top of the list when possible."), "\n", React.createElement(Stack, {
    spacing: 3
  }, React.createElement(Stack.Item, {
    grow: 1,
    basis: 0
  }, React.createElement(Card, null, React.createElement(Stack, {
    direction: "column",
    spacing: 2
  }, React.createElement(Form, null, React.createElement(Form.Group, {
    grouped: true
  }, React.createElement(Form.Radio, {
    label: "Cash",
    name: "Radio-sample"
  }), React.createElement(Form.Radio, {
    label: "Check",
    name: "Radio-sample"
  }), React.createElement(Form.Radio, {
    label: "Debit card",
    name: "Radio-sample"
  }), React.createElement(Form.Radio, {
    label: "Credit card",
    name: "Radio-sample"
  }), React.createElement(Form.Radio, {
    label: "Bank transfer",
    name: "Radio-sample"
  }), React.createElement(Form.Radio, {
    label: "Online payment platforms (e.g. PayPal, Venmo, Google Pay)",
    name: "Radio-sample"
  }))))), React.createElement(Banner, {
    status: "success",
    title: "Do"
  })), React.createElement(Stack.Item, {
    grow: 1,
    basis: 0
  }, React.createElement(Card, null, React.createElement(Stack, {
    direction: "column",
    spacing: 2
  }, React.createElement(Form, null, React.createElement(Form.Group, {
    grouped: true
  }, React.createElement(Form.Radio, {
    label: "Bitcoin",
    name: "Radio-sample"
  }), React.createElement(Form.Radio, {
    label: "Bank transfer",
    name: "Radio-sample"
  }), React.createElement(Form.Radio, {
    label: "Cash",
    name: "Radio-sample"
  }), React.createElement(Form.Radio, {
    label: "Credit card",
    name: "Radio-sample"
  }), React.createElement(Form.Radio, {
    label: "Paypal",
    name: "Radio-sample"
  }), React.createElement(Form.Radio, {
    label: "Check",
    name: "Radio-sample"
  }))))), React.createElement(Banner, {
    status: "critical",
    title: "Don't"
  }))), "\n", React.createElement(_components.h2, {
    id: "give-the-user-the-information-they-need-to-make-an-informed-decision",
    style: {
      position: "relative"
    }
  }, "Give the user the information they need to make an informed decision", React.createElement(_components.a, {
    href: "#give-the-user-the-information-they-need-to-make-an-informed-decision",
    "aria-label": "give the user the information they need to make an informed decision permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "In cases where the radio input label might not provide enough information or context about the option, use Inline help to give the relevant details about the option so the user can make an informed decision."), "\n", React.createElement(_components.p, null, "Don’t add inline help when unneeded or solely to make it match other inputs that have inline help text."), "\n", React.createElement(_components.p, null, "Use sentence case, and only include period if more than one sentence is used."), "\n", React.createElement(_components.p, null, "Follow the ", React.createElement(_components.a, {
    href: "/patterns/forms/#inline-help"
  }, "inline help content guidelines"), "."), "\n", React.createElement(Stack, {
    spacing: 3
  }, React.createElement(Stack.Item, {
    grow: 1,
    basis: 0
  }, React.createElement(Card, null, React.createElement(Stack, {
    direction: "column",
    spacing: 2
  }, React.createElement(Form, null, React.createElement(Form.Group, {
    grouped: true
  }, React.createElement("label", null, "Update auto-renew setting"), React.createElement(Form.Radio, {
    label: "Enabled",
    name: "Radio-sample",
    description: "Agreement will be automatically renewed on its end date"
  }), React.createElement(Form.Radio, {
    label: "Disabled",
    name: "Radio-sample",
    description: "Agreement will expire on its end date unless renewed manually"
  }))))), React.createElement(Banner, {
    status: "success",
    title: "Do"
  })), React.createElement(Stack.Item, {
    grow: 1,
    basis: 0
  }, React.createElement(Card, null, React.createElement(Stack, {
    direction: "column",
    spacing: 2
  }, React.createElement(Form, null, React.createElement(Form.Group, {
    grouped: true
  }, React.createElement("label", null, "Update auto-renew setting"), React.createElement(Form.Radio, {
    label: "Enabled",
    name: "Radio-sample",
    description: "Agreement will automatically renew"
  }), React.createElement(Form.Radio, {
    label: "Disabled",
    name: "Radio-sample",
    description: "Agreement will not automatically renew"
  }))))), React.createElement(Banner, {
    status: "critical",
    title: "Don't"
  }))), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h1, {
    id: "related-assets",
    style: {
      position: "relative"
    }
  }, "Related Assets", React.createElement(_components.a, {
    href: "#related-assets",
    "aria-label": "related assets permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.h2, {
    id: "components",
    style: {
      position: "relative"
    }
  }, "Components", React.createElement(_components.a, {
    href: "#components",
    "aria-label": "components permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "To build a full form, use the ", React.createElement(_components.strong, null, React.createElement(_components.a, {
    href: "/components/form/"
  }, "Form")), " component."), "\n", React.createElement(_components.li, null, "For larger sets of options, a ", React.createElement(_components.strong, null, React.createElement(_components.a, {
    href: "/components/select/"
  }, "Select")), " could be a better option."), "\n", React.createElement(_components.li, null, "For Yes/No or On/Off options or where options are not mutually exclusive, use a ", React.createElement(_components.strong, null, React.createElement(_components.a, {
    href: "/components/checkbox/"
  }, "Checkbox")), "."), "\n"), "\n", React.createElement(_components.h2, {
    id: "patterns",
    style: {
      position: "relative"
    }
  }, "Patterns", React.createElement(_components.a, {
    href: "#patterns",
    "aria-label": "patterns permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "/patterns/forms/"
  }, "Form"), " design pattern for how related controls are ordered."), "\n"), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h1, {
    id: "importing",
    style: {
      position: "relative"
    }
  }, "Importing", React.createElement(_components.a, {
    href: "#importing",
    "aria-label": "importing permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "We recommend using the ", React.createElement(_components.strong, null, React.createElement(_components.a, {
    href: "/components/form/"
  }, "Form")), " shorthand component ", React.createElement(_components.code, null, "<Form.Checkbox radio />"), ". It automatically provide the correct Form grouping structure and spacing. You can import the standalone component for custom Form layouts."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "code: true\n---\nimport { Checkbox } from '@servicetitan/design-system';\n")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
